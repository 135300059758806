import * as React from 'react'
import { useCookies } from 'react-cookie'
import { CROSS_APPLICATION_FORM_COOKIE } from '@constants/cookies'
import { apiUrls } from '@http/api-urls'
import { httpService } from '@http/service'

import { ApplicationFormDto, StepKeys } from '../../types'

import { transformDtoToForm } from './transform.dto'

interface useSessionDataProps {
	minLoan: number
}

export const useSessionData = ({ minLoan }: useSessionDataProps) => {
	const [, , removeCookie] = useCookies([CROSS_APPLICATION_FORM_COOKIE])
	const [formValues, setFormValues] = React.useState<ApplicationFormDto>(
		transformDtoToForm(undefined, minLoan)
	)
	const [active, setActive] = React.useState<boolean | null>(null)

	const handleGetLead = React.useCallback(async (onSuccess?: () => void) => {
		try {
			const response = await httpService.get<Record<string, any>>(apiUrls.lead.get())
			setFormValues(transformDtoToForm(response))
			setActive(true)
			onSuccess && onSuccess()
		} catch {
			removeCookie(CROSS_APPLICATION_FORM_COOKIE)
			setActive(false)
		}
	}, [])

	React.useEffect(() => {
		handleGetLead()
	}, [])

	return {
		form: {
			refetch: handleGetLead,
			values: formValues,
			setValues: setFormValues,
		},
		active,
		forcedStep: active === null ? null : ((active ? 'esia-auth' : 'primary') as StepKeys),
	}
}
