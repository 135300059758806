import { RegExps } from '@constants/forms'
import { DadataAddress, DadataFio, DaDataSuggestion } from '@http/dadata/models'

export const transformPhoneToDto = (phone: string): string =>
	phone.replace(RegExps.phone, '$1$2$3$4')

export const transformFioToDto = (
	fio: DaDataSuggestion<DadataFio> | string,
	defaultTo = null as string | null
) => {
	if (typeof fio === 'string') {
		return {
			name: fio,
			fullname: fio,
			surname: defaultTo,
			patronymic: defaultTo,
		}
	}

	return {
		fullname: fio?.value || defaultTo,
		name: fio?.data?.name || fio?.value || defaultTo,
		surname: fio?.data?.surname || defaultTo,
		patronymic: fio?.data?.patronymic || defaultTo,
	}
}

export const transformStringDateToDto = (date: string) => {
	return date.replace(/(\d{2}).(\d{2}).(\d{4})/g, '$3-$2-$1')
}

export const transformAddressToDto = (address: DadataAddress | string) => {
	if (typeof address === 'string') {
		return {
			mergedAddress: address,
		}
	}

	return address
}
