import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import { Input } from '@shared/components/text-field/input'
import { InputProps } from '@shared/components/text-field/model'
import { useEventListener } from '@shared/hooks/use-event-listener'
import { runOnClient } from '@shared/pipes/client'
import { Field } from 'formik'

import { IOwnProps } from './model'

import styles from './style.module.scss'

const AutocompleteOption = ({ value, isSelected }) => <div className={styles.option}>{value}</div>

const PaperComponent = ({
	children,
	dropdownPlacement,
}: PropsWithChildren<{ dropdownPlacement: InputProps['dropdownPlacement'] | null }>) => (
	<div {...{ 'data-dropdown-placement': dropdownPlacement }} className={styles.paper}>
		{children}
	</div>
)

const defaults = {
	filterOptions: (options) => options,
	getOptionLabel: (option) => (typeof option === 'string' ? option : option.value),
	getOptionSelected: (option, value) => option.value === value.value,
}

export const Autocomplete = ({
	helperText,
	disabled,
	label,
	name,
	onChange,
	inputRequired,
	...rest
}: PropsWithChildren<IOwnProps>) => {
	const [dropdownPlacement, setDropdownPlacement] = useState<
		InputProps['dropdownPlacement'] | null
	>(null)
	const detectDropdownPlacement = useCallback(() => {
		runOnClient(() => {
			const nodeEl = document.querySelector('div[role="presentation"], .MuiAutocomplete-popper')
			if (nodeEl) {
				return setDropdownPlacement(
					nodeEl.getAttribute('x-placement') as InputProps['dropdownPlacement']
				)
			}

			setDropdownPlacement(null)
		})
	}, [])

	useEventListener('scroll', detectDropdownPlacement)

	useEffect(() => {
		setTimeout(detectDropdownPlacement, 0)
	}, [rest.options])

	const toggleDropdownVisible = useCallback(() => {
		setTimeout(detectDropdownPlacement, 0)
	}, [detectDropdownPlacement])

	const autocompleteProps = {
		...rest,
		filterOptions: rest.filterOptions || defaults.filterOptions,
		getOptionLabel: rest.getOptionLabel || defaults.getOptionLabel,
		getOptionSelected: rest.getOptionSelected || defaults.getOptionSelected,
	}

	return (
		<>
			<Field name={name}>
				{({ field, form, meta }) => {
					return (
						<MuiAutocomplete
							{...autocompleteProps}
							value={rest.value != null ? rest.value : field.value}
							loading={form.isSubmitting}
							disabled={form.isSubmitting || disabled}
							onBlur={field.onBlur}
							onOpen={toggleDropdownVisible}
							onClose={toggleDropdownVisible}
							noOptionsText='Нет доступных значений'
							onChange={(_, val) => (onChange ? onChange(val) : form.setFieldValue(name, val))}
							PaperComponent={(props) => PaperComponent({ ...props, dropdownPlacement })}
							renderOption={(option, params) => {
								return AutocompleteOption({
									isSelected: params.selected,
									value: autocompleteProps.getOptionLabel(option),
								})
							}}
							renderInput={(params: any) => {
								const inputProps = { ...params.inputProps, ref: undefined, className: undefined }
								return (
									<div ref={params.InputProps.ref}>
										<Input
											{...inputProps}
											name={name}
											label={label}
											helperText={helperText}
											required={inputRequired}
											endAdornment={params.InputProps.endAdornment}
											dropdownPlacement={rest.options.length === 0 ? undefined : dropdownPlacement}
											inputRef={params.inputProps.ref}
											error={meta.touched ? meta.error : ''}
										/>
									</div>
								)
							}}
						/>
					)
				}}
			</Field>
		</>
	)
}
