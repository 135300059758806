import * as React from 'react'
import { AnchorsOfForms } from '@constants/forms'
import { apiUrls } from '@http/api-urls'
import { httpService } from '@http/service'
import { TextField } from '@shared/components/text-field'
import { useSmsRetry } from '@shared/hooks/use-sms-retry'
import scrollToElement from '@shared/pipes/scroll'
import cx from 'clsx'
import { Form, FormikProvider, useFormik } from 'formik'
import { useRouter } from 'next/router'

import { ApplicationFormContext } from '../../../Application'
import { transformFormToDto } from '../../../transform.dto'
import { sendSmsCode } from '../api'
import { Submit } from '../Submit'

import styles from './ConfirmSms.module.scss'

interface Props {
	phone: string
	changePhone: () => void
}

type FormStatuses =
	| 'pending-confirmation'
	| 'confirmed'
	| 'lead-is-created'
	| 'wrong-code'
	| 'created-lead-error'

export const ConfirmSms = React.memo(({ phone, changePhone }: Props) => {
	const router = useRouter()
	const context = React.useContext(ApplicationFormContext)

	const formik = useFormik({
		initialValues: {
			code: '',
		},
		initialStatus: 'pending-confirmation' as FormStatuses,
		onSubmit: async ({ code }, { setStatus }) => {
			const formStatus: FormStatuses = formik.status

			try {
				if (formStatus === 'pending-confirmation' || formStatus === 'wrong-code') {
					await httpService.post(apiUrls.sms.confirm(code))
					setStatus('confirmed' as FormStatuses)
				}
				await httpService.post(
					apiUrls.lead.create(),
					transformFormToDto(context.formController.values, true, router.asPath)
				)

				context.formController.refetch(() => {
					context.cookieController.update(true)
					context.stepController.go.forward?.()
					scrollToElement(`#${AnchorsOfForms.application}`, { time: 0 })
				})
			} catch (error: any) {
				if (error?.response?.status === 401) {
					setStatus('wrong-code' as FormStatuses)
				} else {
					setStatus('created-lead-error' as FormStatuses)
				}
			}
		},
	})

	const formStatus: FormStatuses = formik.status

	const onSendSms = React.useCallback(async () => {
		formik.setSubmitting(true)

		try {
			await sendSmsCode(phone)
		} finally {
			formik.setSubmitting(false)
		}
	}, [])

	const { isRunning, text, retry } = useSmsRetry(onSendSms)

	const codeProps: React.ComponentProps<typeof TextField> = {
		mask: '9999',
		maskChar: '',
		required: true,
		onChange: formik.handleChange,
		value: formik.values.code,
		className: styles.input,
		inputMode: 'numeric',
		error: formStatus === 'wrong-code' && '',
		name: 'code',
		label: 'Код из смс',
		disabled: formik.isSubmitting,
	}

	return (
		<FormikProvider value={formik}>
			<Form autoComplete='off'>
				<h4 className={styles.title}>Подтвердите номер телефона кодом из смс</h4>
				<p className={cx(styles.text, styles.indentXs)}>
					Мы отправили на номер <br /> {phone} код подтверждения.
				</p>
				<p className={cx(styles.text, styles.indentS)}>
					Введите его, чтобы мы смогли рассмотреть заявку
				</p>
				<TextField {...codeProps} />
				<div className={styles.bottom}>
					<span
						className={cx(styles.linkBtn, { [styles.disabled]: isRunning || formik.isSubmitting })}
						onClick={!isRunning ? retry : undefined}
					>
						{text}
					</span>
					<span
						className={cx(styles.linkBtn, { [styles.disabled]: formik.isSubmitting })}
						onClick={changePhone}
					>
						Изменить номер телефона
					</span>
					{formStatus === 'wrong-code' && (
						<span className={styles.error}>Неправильный код подтверждения</span>
					)}
					{formStatus === 'created-lead-error' && (
						<span className={styles.error}>Произошла ошибка, попробуйте ещё раз</span>
					)}
				</div>
				<Submit />
			</Form>
		</FormikProvider>
	)
})
