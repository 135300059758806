import React, { PropsWithChildren } from 'react'
import cx from 'classnames'

import { ButtonProps } from './model'

import styles from './style.module.scss'

export const View = ({
	children,
	onClick,
	type = 'button',
	variant = 'filled',
	size = 'l',
	disabled,
	className,
	...rest
}: PropsWithChildren<ButtonProps>) =>
	React.createElement(
		'button',
		{
			className: cx(styles.button, styles[`size${size.toLocaleUpperCase()}`], className, {
				[styles.disabled]: disabled,
				[styles.buttonFilled]: variant === 'filled',
				[styles.buttonOutlined]: variant === 'outlined',
				[styles.buttonLink]: variant === 'link',
			}),
			...{ onClick, type, disabled },
			...rest,
		},
		children
	)
