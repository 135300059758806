import React, { useCallback, useEffect, useState } from 'react'
import Slider from '@appigram/react-rangeslider'
import { removeSpaces } from '@shared/utils/helpers'
import cx from 'classnames'

import { StateProps } from '../model'

import styles from './style.module.scss'

export const NewView = ({
	min = 50000, // 50 тыс
	max = 20000000, // 20 млн
	step = 50000, // 50 тыс
	value,
	inputValue: outerInputValue,
	handleChange,
	handleBlur,
	handleInputFocus,
	resultClassName,
	labels,
	disabled,
	Icon,
}: StateProps) => {
	const [inputValue, setInputValue] = useState<string>(outerInputValue)

	const handleInputChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
		setInputValue(removeSpaces(e.currentTarget.value))
	}, [])

	const handleOnBlur = () => {
		handleChange(inputValue)
		handleInputFocus()
	}

	useEffect(() => {
		setInputValue(outerInputValue)
	}, [outerInputValue])

	return (
		<div className={styles.wrap}>
			<div className={styles.inputWrap}>
				<input
					className={cx(styles.result, resultClassName)}
					value={inputValue}
					disabled={disabled}
					onChange={handleInputChange}
					onBlur={handleOnBlur}
				/>
				{Icon && <Icon />}
			</div>
			<Slider
				min={min}
				max={max}
				step={step}
				value={value}
				tooltip={false}
				onChangeComplete={handleBlur}
				orientation='horizontal'
				onChange={handleChange}
				labels={labels}
			/>
		</div>
	)
}
