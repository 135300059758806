export enum Product {
	CAR_SECURED_LOAN = 'CAR_SECURED_LOAN',
	CONSUMER_CREDIT = 'CONSUMER_CREDIT',
	MORTGAGE = 'MORTGAGE',
	REAL_ESTATE_LOAN = 'REAL_ESTATE_LOAN',
}

export enum ProductByPage {
	'/' = Product.REAL_ESTATE_LOAN,
	'/loan' = Product.REAL_ESTATE_LOAN,
	'/loan/auto' = Product.CAR_SECURED_LOAN,
	'/auto-loan' = Product.CAR_SECURED_LOAN,
}
