import { useState } from 'react'
import { Masks } from '@constants/forms'

const maskChar = '_'

const formatePhoneNumber = (code: string, value: string) =>
	`${code} (${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 8)}-${value.slice(8, 10)}`

export const usePhoneMask = (blockTransformToEight = true) => {
	const [mask, setMask] = useState(Masks.phone)

	const beforeMaskedValueChange = (newState, oldState, userInput) => {
		// Подставляется код "8" в маску вместо "+7"
		if (blockTransformToEight) {
			if (
				newState?.selection?.start === 5 &&
				userInput === '8' &&
				!mask.startsWith('8') &&
				oldState.value.match(new RegExp(maskChar, 'g'))?.length === 10
			) {
				setMask(Masks.phoneStartsWith8)

				return {
					value: '',
					selection: { start: 3, end: 3 },
				}
			}
		}

		// При пустом инпуте, возвращается маска с +7
		if (!newState?.value) {
			setMask(Masks.phone)
		}

		// копирование и вставка номера с кодом "+7"
		if (userInput?.length === 12 && userInput.startsWith('+7')) {
			setMask(Masks.phone)

			return {
				...newState,
				value: formatePhoneNumber('+7', userInput.slice(2)),
			}
		}

		// копирование и вставка номера с кодом "8"
		if (userInput?.length === 11 && userInput.startsWith('8')) {
			setMask(Masks.phone)

			return {
				...newState,
				value: formatePhoneNumber('+7', userInput.slice(1)),
			}
		}

		return newState
	}

	return { mask, maskChar, beforeMaskedValueChange }
}
