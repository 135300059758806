import React from 'react'
import Rub from '@shared/svg/rub.svg'

import { IStateProps } from './model'

import styles from './style.module.scss'

export const ProgressBar = ({ percent, text }: IStateProps) => (
	<div className={styles.bar}>
		<div className={styles.content}>
			<Rub className={styles.icon} />
			<span>{`${percent}% ${text || 'Вероятность одобрения кредита'}`}</span>
		</div>
		<div className={styles.track} style={{ width: `${percent}%` }} />
	</div>
)
