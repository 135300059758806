import * as React from 'react'
import { useCookies } from 'react-cookie'
import { CROSS_APPLICATION_FORM_COOKIE } from '@constants/cookies'

import { ApplicationFormCookie, StepKeys } from '../../types'

export const useFormCookie = (step: StepKeys, isSessionActive: boolean) => {
	const [cookies, setCookie] = useCookies([CROSS_APPLICATION_FORM_COOKIE])

	const statuses = (cookies[CROSS_APPLICATION_FORM_COOKIE] ?? {
		'esia-auth': false,
		primary: !!isSessionActive,
	}) as ApplicationFormCookie

	const update = React.useCallback(
		(value: boolean) => {
			setCookie(
				CROSS_APPLICATION_FORM_COOKIE,
				Object.assign({}, statuses, {
					[step]: value,
				}),
				{
					domain: process.env.NODE_ENV === 'development' ? undefined : '.credit.club',
				}
			)
		},
		[cookies, step, setCookie]
	)

	return {
		update,
		finished: !!statuses[step],
	}
}
