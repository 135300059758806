import React from 'react'
import cx from 'classnames'

import { ViewProps } from './model'

import styles from './style.module.scss'

export const View = ({ min, className }: ViewProps) => (
	<div className={cx(styles.loading, min && styles.min, className)}>
		<div className={styles.ring}>
			<div />
			<div />
			<div />
			<div />
		</div>
	</div>
)
