import {
	DEFAULT_LOAN_SUM,
	DEFAULT_PERCENT,
	DEFAULT_PERIOD,
	PROPERTY_VALUE_RATIO,
} from '@constants/loan'
import { formatPhone } from '@shared/utils/helpers'

import { ApplicationFormDto } from '../../types'

export const transformDtoToForm = (
	data?: Record<string, any>,
	minLoan?: number
): ApplicationFormDto => ({
	loan: data?.calculation?.loan || minLoan || DEFAULT_LOAN_SUM,
	percent: data?.calculation?.percent ?? DEFAULT_PERCENT,
	period: data?.calculation?.period ?? DEFAULT_PERIOD,
	payment: data?.calculation?.payment ?? PROPERTY_VALUE_RATIO * DEFAULT_LOAN_SUM,
	type: data?.calculation?.type ?? 'ANNUITY',
	purpose: data?.marketingData?.purpose ?? '',
	participant: {
		fullname: [
			data?.participants[0]?.surname,
			data?.participants[0]?.name,
			data?.participants[0]?.patronymic,
		]
			.filter(Boolean)
			.join(' '),
		name: data?.participants[0]?.name ?? '',
		surname: data?.participants[0]?.surname ?? '',
		patronymic: data?.participants[0]?.patronymic ?? '',
		email: data?.participants[0]?.email ?? '',
		phone: formatPhone(data?.participants[0]?.phone ?? '', true),
	},
	agreementPersonalData: data?.agreementPersonalData,
	agreementAdNewsletter: data?.agreementAdNewsletter,
})
