import * as React from 'react'
import { DadataFio, DaDataSuggestion } from '@http/dadata/models'
import { transformFioToDto } from '@http/service/shared-transformers'
import { Autocomplete } from '@shared/components/autocomplete'
import { Checkbox } from '@shared/components/checkbox'
import { FormProps } from '@shared/components/forms/application/cross/types'
import { InputRange } from '@shared/components/input-range'
import { TextField } from '@shared/components/text-field'
import { useAsyncAutocomplete } from '@shared/hooks/use-async-autocomplete'
import { ExternalDadataFioRequestRunner } from '@shared/hooks/use-async-autocomplete/request-runners'
import { usePhoneMask } from '@shared/hooks/use-phone-mask'
import { emitGTMEvent, formatCalcSum } from '@shared/utils/helpers'
import { Form, FormikProvider, useFormik } from 'formik'
import { isEqual } from 'lodash'
import { useRouter } from 'next/router'

import { ApplicationFormContext } from '../../Application'
import { Card } from '../../components/Card'
import { ProgressBar } from '../../components/ProgressBar'
import { useProgressBar } from '../../hooks'

import { sendSmsCode, updateExistedLead } from './api'
import { ConfirmSms } from './ConfirmSms'
import { purposeOptions } from './data'
import { Submit } from './Submit'
import { FormStatus } from './types'
import { validationSchema, validationSchemaWow } from './validation'

import styles from './Primary.module.scss'

const inputRangeClassNames = {
	resultClassName: styles.inputRangeResult,
	activeTrackClassName: styles.activeTrack,
	trackClassName: styles.track,
}

const wow = '/wow'

export const Primary = (props: FormProps) => {
	const context = React.useContext(ApplicationFormContext)
	const router = useRouter()

	const formik = useFormik<(typeof context)['formController']['values']>({
		initialValues: context.formController.values,
		validationSchema: router.pathname === wow ? validationSchemaWow : validationSchema,
		initialStatus: (context.cookieController.finished && context.isSessionActive
			? 'update-existed-lead'
			: 'send-sms') as FormStatus,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			emitGTMEvent('basic-send-form')

			switch (formik.status as FormStatus) {
				case 'send-sms': {
					setSubmitting(true)

					try {
						await sendSmsCode(values.participant.phone)
						context.formController.setValues(values)
						setStatus('confirm-sms' as FormStatus)
					} finally {
						setSubmitting(false)
					}

					return
				}

				case 'update-existed-lead': {
					if (isEqual(values, context.formController.values)) {
						context.stepController.go.forward?.()
					} else {
						setSubmitting(true)

						try {
							await updateExistedLead(values, router.asPath)
							context.formController.refetch(context.stepController.go.forward ?? undefined)
						} catch {
							setSubmitting(false)
						}
					}

					return
				}
			}
		},
	})

	const progress = useProgressBar(formik.values)

	const phoneInputRef = React.useRef<HTMLInputElement>(null!)

	const formStatus: FormStatus = formik.status
	const disableInputs = formik.isSubmitting

	const handlePhoneNumberForSms = React.useCallback(() => {
		formik.setStatus('send-sms' as FormStatus)
		phoneInputRef.current?.focus()
	}, [])

	const submitProps: React.ComponentProps<typeof Submit> = {
		disabled:
			formik.isSubmitting ||
			(router.pathname === wow &&
				(!formik.values.agreementPersonalData || !formik.values.agreementAdNewsletter)),
	}

	const { mask, maskChar, beforeMaskedValueChange } = usePhoneMask(false)

	const [fioAutocompleteOptions, , handleSetQuery] = useAsyncAutocomplete<
		DaDataSuggestion<DadataFio>
	>(new ExternalDadataFioRequestRunner())

	const fullnameProps: React.ComponentProps<typeof Autocomplete> = {
		label: 'ФИО',
		freeSolo: true,
		autoSelect: false,
		blurOnSelect: false,
		inputRequired: true,
		name: 'participant.fullname',
		getOptionSelected: React.useCallback((option, value) => value === option.value, []),
		onChange: React.useCallback(
			(value) =>
				formik.setValues({
					...formik.values,
					participant: { ...formik.values.participant, ...transformFioToDto(value, '') },
				}),
			[formik.values]
		),
		disabled: disableInputs,
		options: fioAutocompleteOptions,
		onInputChange: React.useCallback(
			(_, query) => {
				handleSetQuery(query)
				formik.setValues({
					...formik.values,
					participant: { ...formik.values.participant, ...transformFioToDto(query, '') },
				})
			},
			[formik.values]
		),
	}

	const phoneProps: React.ComponentProps<typeof TextField> = {
		name: 'participant.phone',
		value: formik.values.participant.phone,
		label: 'Номер телефона',
		disabled: disableInputs || formStatus === 'confirm-sms',
		inputMode: 'tel',
		inputRef: phoneInputRef,
		required: true,
		mask,
		maskChar,
		beforeMaskedValueChange,
	}

	const emailProps: React.ComponentProps<typeof TextField> = {
		name: 'participant.email',
		value: formik.values.participant.email,
		required: true,
		inputMode: 'email',
		disabled: disableInputs,
		label: 'Электронная почта',
	}

	const loanProps: React.ComponentProps<typeof InputRange> = {
		value: formik.values.loan,
		onChange: React.useCallback((value) => formik.setFieldValue('loan', +value, false), []),
		formatLabel: React.useCallback((value: number | string) => `${formatCalcSum(value)} ₽`, []),
		min: props.minLoan || 200000,
		max: props.maxLoan || 15000000,
		disabled: disableInputs,
		...inputRangeClassNames,
	}

	const periodProps: React.ComponentProps<typeof InputRange> = {
		value: formik.values.period,
		onChange: React.useCallback((value) => formik.setFieldValue('period', +value, false), []),
		min: 1,
		max: 60,
		step: 1,
		disabled: disableInputs,
		...inputRangeClassNames,
	}

	const purposesProps: React.ComponentProps<typeof Autocomplete> = {
		label: 'Цель займа',
		blurOnSelect: false,
		value: formik.values.purpose,
		inputRequired: true,
		disabled: disableInputs,
		name: 'purpose',
		onChange: React.useCallback(
			(option) => formik.setFieldValue('purpose', option?.value || ''),
			[formik.values]
		),
		onInputChange: React.useCallback(
			(_, query) => formik.setFieldValue('purpose', query || ''),
			[]
		),
		options: purposeOptions,
	}

	const confirmSmsProps: React.ComponentProps<typeof ConfirmSms> = {
		phone: formik.values.participant.phone,
		changePhone: handlePhoneNumberForSms,
	}

	const agreementPersonalDataProps: React.ComponentProps<typeof Checkbox> = {
		label: (
			<a
				target='_blank'
				rel='noopener noreferrer'
				className={styles.link}
				href='https://storage.yandexcloud.net/creditclub-frontend/static/docs/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D1%8F/%D0%9C%D0%B5%D0%B3%D0%B0%D1%84%D0%BE%D0%BD/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5%20%D0%BD%D0%B0%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D1%83%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf'
			>
				Согласие на обработку персональных данных
			</a>
		),
		value: formik.values.agreementPersonalData,
		name: 'agreementPersonalData',
	}

	const agreementAdNewsletter: React.ComponentProps<typeof Checkbox> = {
		label: (
			<a
				target='_blank'
				rel='noopener noreferrer'
				className={styles.link}
				href='https://storage.yandexcloud.net/creditclub-frontend/static/docs/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D1%8F/%D0%9C%D0%B5%D0%B3%D0%B0%D1%84%D0%BE%D0%BD/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5%20%D0%BD%D0%B0%20%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D1%83.pdf'
			>
				Согласие на получение рекламных материалов
			</a>
		),
		value: formik.values.agreementAdNewsletter,
		name: 'agreementAdNewsletter',
	}

	return (
		<Card header={`Шаг ${context.stepController.order} из ${context.stepController.total}`}>
			<ProgressBar text={props.processBarText} percent={progress} />
			<h2 className={styles.h2}>{props.formTitle || 'Заявка на кредит'}</h2>
			<h3 className={styles.h3}>
				{props.formDescription || 'Быстрое решение вашего финансового вопроса'}
			</h3>
			<FormikProvider value={formik}>
				<Form id='application-form-cross' autoComplete='off'>
					<div role='input-wrap'>
						<Autocomplete {...fullnameProps} />
					</div>
					<div role='inputs-wrap-2'>
						<div role='input-wrap'>
							<TextField {...phoneProps} />
						</div>
						<div role='input-wrap'>
							<TextField {...emailProps} />
						</div>
					</div>
					<div className={styles.calculatorInputsWrap}>
						<div className={styles.calculatorInput}>
							<h4 className={styles.calculatorInputLabel}>Сумма займа</h4>
							<InputRange {...loanProps} />
						</div>
						<div className={styles.calculatorInput}>
							<h4 className={styles.calculatorInputLabel}>Срок займа, мес</h4>
							<InputRange {...periodProps} />
						</div>
					</div>
					<div role='input-wrap'>
						<Autocomplete {...purposesProps} />
					</div>
					{router.pathname === wow && (
						<div className={styles.agreements}>
							<Checkbox {...agreementPersonalDataProps} />
							<Checkbox {...agreementAdNewsletter} />
						</div>
					)}
					{(formStatus === 'send-sms' || formStatus === 'update-existed-lead') && (
						<Submit {...submitProps} />
					)}
				</Form>
			</FormikProvider>
			{formStatus === 'confirm-sms' && <ConfirmSms {...confirmSmsProps} />}
		</Card>
	)
}
