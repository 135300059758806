import * as React from 'react'

export const useSmsRetry = <T extends () => Promise<any>>(
	retryApiRequest: T,
	_countdown?: number
) => {
	const [{ countdown, isRunning }, setTimer] = React.useState({
		countdown: _countdown || 30,
		isRunning: true,
	})
	const interval = React.useRef(0)

	React.useEffect(() => {
		if (!isRunning) {
			clearInterval(interval.current)
			return undefined
		}

		interval.current = window.setInterval(() => {
			setTimer((p) => ({ ...p, countdown: p.countdown - 1 }))
		}, 1000)

		return () => {
			clearInterval(interval.current)
		}
	}, [isRunning])

	const handleReset = React.useCallback(async () => {
		try {
			await retryApiRequest()
			setTimer({ countdown: 30, isRunning: true })
		} catch {
			//
		}
	}, [])

	React.useEffect(() => {
		if (countdown <= 0) setTimer({ countdown: 0, isRunning: false })
	}, [countdown])

	return {
		retry: handleReset,
		isRunning,
		text: isRunning
			? `Отправить код повторно через ${
					_countdown && _countdown > 59
						? `0:${countdown}`
						: countdown >= 10
						? `0:${countdown}`
						: `0:0${countdown}`
			  }`
			: 'Отправить код повторно',
	}
}
