import React from 'react'
import { Checkbox as MuiCheckbox, CheckboxProps, FormControlLabel } from '@material-ui/core'
import { ErrorMessage, Field } from 'formik'
import CheckboxChecked from '@shared/svg/checkbox/checked.svg'
import CheckboxNormal from '@shared/svg/checkbox/normal.svg'

import styles from './styles.module.scss'

interface IStateProps extends CheckboxProps {
	label: React.ReactNode
}

const svgIconInlineStyle = { width: 'initial', height: 'initial' }

/**
 * Компонент работает в связке с formik
 * @constructor
 */
export const Checkbox = ({ label, ...checkboxProps }: IStateProps) => {
	return (
		<Field name={checkboxProps.name} type='checkbox'>
			{({ form, field }) => (
				<>
					<FormControlLabel
						label={label}
						control={
							<MuiCheckbox
								{...checkboxProps}
								checked={field.checked}
								size='small'
								classes={{ root: styles.iconRoot }}
								checkedIcon={<CheckboxChecked style={svgIconInlineStyle} />}
								icon={<CheckboxNormal style={svgIconInlineStyle} />}
								onKeyPress={({ key }) => {
									if (key === 'Enter') {
										form.setFieldValue(checkboxProps.name, !field.checked)
									}
								}}
								onChange={(event, checked) => {
									checkboxProps.onChange && checkboxProps.onChange(event, checked)
									form.setFieldValue(checkboxProps.name, !field.checked)
								}}
							/>
						}
					/>
					<ErrorMessage name={checkboxProps.name!} />
				</>
			)}
		</Field>
	)
}
