import { DadataFio, DaDataSuggestion } from '@http/dadata/models'

export const transformDadataFioToViewModel = (source: any[]): DaDataSuggestion<DadataFio>[] => {
	return source.map((dadataSuggest) => ({
		...dadataSuggest,
		unrestrictedValue: dadataSuggest.unrestricted_value,
	}))
}

export const transformAutocompleteFioResponseToOptions = (data) =>
	data ? transformDadataFioToViewModel(data.suggestions) : []
