export const purposeOptions = [
	'Ремонт',
	'Рефинансирование кредитов',
	'Погашение кредитов',
	'Покупка автомобиля',
	'Развитие бизнеса',
	'Покупка недвижимости',
	'Пополнение оборотных средств',
	'Исполнение контракта',
	'Покупка оборудования',
	'Образование',
	'Лечение',
	'Иные цели',
].map((el) => ({ label: el, value: el }))
