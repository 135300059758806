import { apiUrls } from '@http/api-urls'
import { httpService } from '@http/service'
import { HttpOptions } from '@http/service/options'
import { transformPhoneToDto } from '@http/service/shared-transformers'
import { consoleDebug, isDebug } from '@shared/pipes/is-debug'

import { transformFormToDto } from '../../transform.dto'
import { ApplicationFormDto } from '../../types'

export const sendSmsCode = (phone: string) => {
	if (isDebug()) {
		return consoleDebug('[Primary form] - prevent send form')
	}

	return httpService.post(apiUrls.sms.send(transformPhoneToDto(phone)))
}

export const updateExistedLead = (form: ApplicationFormDto, page?: string) => {
	if (isDebug()) {
		return consoleDebug('[Primary form] - prevent send form')
	}

	return httpService.patch(
		apiUrls.lead.update(),
		transformFormToDto(form, false, page),
		new HttpOptions().setHeader('Content-type', 'application/merge-patch+json')
	)
}
