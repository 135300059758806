import moment from 'moment'
import { addMethod, setLocale, string, StringSchema } from 'yup'

export const customizeYup = () => {
	setLocale({
		mixed: {
			required: 'Это обязательное поле',
		},
		string: {
			email: 'Введён неккоректный электронный адрес',
		},
	})

	addMethod<StringSchema>(string, 'checkStringDate', function ({ message, format }) {
		return this.test('validDate', message, async function (input) {
			const parsed = moment(input, format, true)
			return parsed.isValid() || this.createError({ path: this.path, message })
		})
	})
}
