import * as React from 'react'

import { StepKeys } from '../../types'

interface StepMove {
	back: StepKeys | null
	forward: StepKeys | null
	order: number
}

const stepOrder = ['primary', 'esia-auth'] as StepKeys[]

const steps = stepOrder.reduce((acc, curr, index) => {
	switch (curr) {
		case 'primary': {
			acc.primary = {
				back: null,
				forward: 'esia-auth',
				order: index + 1,
			}
			return acc
		}
		case 'esia-auth': {
			acc['esia-auth'] = {
				back: 'primary',
				forward: null,
				order: index + 1,
			}
			return acc
		}
		default:
			return acc
	}
}, {} as Record<StepKeys, StepMove>)

export const useSteps = (forceStep: StepKeys) => {
	const [step, setStep] = React.useState(forceStep)

	React.useEffect(() => {
		setStep(forceStep)
	}, [forceStep])

	return {
		go: {
			back: steps[step]?.back ? () => setStep(steps[step].back!) : null,
			forward: steps[step]?.forward ? () => setStep(steps[step].forward!) : null,
		},
		order: steps[step]?.order ?? null,
		current: step,
		total: 4,
	}
}
