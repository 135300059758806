import { useEffect, useState } from 'react'
import { AutocompleteRequestRunner } from '@shared/hooks/use-async-autocomplete/request-runners'
import axios from 'axios'

import { useIsMounted } from '../use-is-mounted'

export type UseAsyncAutocompleteReturn<T> = [T[], boolean, (searchValue: string) => void]

export const useAsyncAutocomplete = <T = any>(
	requestRunner: AutocompleteRequestRunner
): UseAsyncAutocompleteReturn<T> => {
	const isMounted = useIsMounted()
	const [response, setResponse] = useState<T[]>([])
	const [isLoading, setLoadingStatus] = useState(false)
	const [query, setQuery] = useState('')

	useEffect(() => {
		if (query.length) {
			const source = axios.CancelToken.source()
			setLoadingStatus(true)

			requestRunner
				.run({ query, axiosCancelToken: source })
				.then((data) => {
					if (isMounted.current) {
						setResponse(data)
					}
				})
				.catch((err) => {
					if (!axios.isCancel(err)) {
						throw err
					}
				})
				.finally(() => {
					if (isMounted.current) {
						setLoadingStatus(false)
					}
				})

			return () => {
				source.cancel('Cancelling in cleanup')
			}
		} else {
			if (response) {
				return setResponse([])
			}
		}
	}, [query])

	return [response, isLoading, setQuery]
}
