import * as React from 'react'
import { useCookies } from 'react-cookie'
import { NEW_CROSS_APPLICATION_FORM_COOKIE } from '@constants/cookies'
import { apiUrls } from '@http/api-urls'
import { Button } from '@shared/components/button'
import { Image } from '@shared/components/image'
import { getConfigProperty } from '@shared/pipes/config'
import { isClient } from '@shared/utils/is-client'
import { useRouter } from 'next/router'

import { ApplicationFormContext } from '../../Application'
import { Card } from '../../components/Card'
import { ProgressBar } from '../../components/ProgressBar'
import { useProgressBar } from '../../hooks'

import styles from './EsiaAuth.module.scss'

export const EsiaAuth = () => {
	const [, , removeCookie] = useCookies([NEW_CROSS_APPLICATION_FORM_COOKIE])

	const context = React.useContext(ApplicationFormContext)
	const router = useRouter()

	const handleEsiaAuth = React.useCallback(() => {
		removeCookie(NEW_CROSS_APPLICATION_FORM_COOKIE)
		location.href = `${getConfigProperty('API_URL')}${apiUrls.esia.auth()}`
	}, [])

	const progress = useProgressBar(context.formController.values)

	return (
		<Card
			header={
				<>
					<div className={styles.step}>
						{`Шаг ${context.stepController.order} из ${context.stepController.total}`}
					</div>
					<div className={styles.hint}>Заявка на кредит</div>
				</>
			}
		>
			<ProgressBar percent={progress} />
			{!context.cookieController.finished && (
				<>
					<h2 className={styles.h2}>Заполните заявку автоматически</h2>
					<Image name='gosuslugi' className={styles.image} />
				</>
			)}
			{!context.cookieController.finished && (
				<h5>
					Авторизуйтесь через портал Госуслуг, чтобы продолжить оформление заявки на кредит. Это
					безопасно и удобно: вам не придётся вводить всю информацию вручную — данные загрузятся
					автоматически.
				</h5>
			)}
			<div className={styles.controls}>
				{!context.cookieController.finished && (
					<Button
						onClick={context.stepController.go.back ?? undefined}
						className={styles.back}
						variant='outlined'
					>
						Назад
					</Button>
				)}
				{context.cookieController.finished ? (
					<Button onClick={() => router.push('/application')} className={styles.esia}>
						Продолжить заполнение
					</Button>
				) : (
					<Button
						data-gtm-element='basic-gos-enter-click'
						onClick={handleEsiaAuth}
						className={styles.esia}
					>
						Авторизоваться через Госуслуги
					</Button>
				)}
			</div>
		</Card>
	)
}
