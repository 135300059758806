import { Paths } from '@shared/menu-items/paths'
import { isClient } from '@shared/utils/is-client'

export const handleSelectorIfExists = <T extends Element>(
	selector: string,
	fn: (element: T) => void
): void => {
	const query = document.querySelector(selector)
	if (isClient && query) {
		return fn(query as T)
	}

	console.error(`runSelectorIfExists | selector ${selector} not found`)
}

export const changeDocumentHash = (hash: string): void => {
	if (window.history.pushState) {
		return window.history.pushState(null, '', '#' + hash)
	}
	window.location.hash = hash
}

export const getFullLinkByPath = (path: Paths) => {
	if (isClient) {
		return `${document.location.origin}${path}`
	}

	return path
}
