import { RegExps } from '@constants/forms'

import { ApplicationFormDto } from '../../types'

const WEIGHTS = {
	purpose: 5,
	name: 2,
	surname: 2,
	patronymic: 2,
	phone: 10,
	email: 4,
}

export const useProgressBar = (data: ApplicationFormDto) => {
	const deepCalc = (obj) =>
		Object.keys(obj).reduce((acc, key) => {
			if (typeof obj[key] === 'object') {
				return acc + deepCalc(obj[key])
			} else {
				if (obj[key] && key === 'phone' ? RegExps.phone.test(obj[key]) : obj[key] && WEIGHTS[key]) {
					return acc + WEIGHTS[key]
				}

				return acc
			}
		}, 0)

	return deepCalc(data)
}
