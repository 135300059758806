import { useCallback, useEffect, useState } from 'react'
import { breaksHundreds, isNotPositiveIntegerValue, removeSpaces } from '@shared/utils/helpers'

import { NewView } from './viewNew/new-view'
import { View } from './viewOld/view'
import { OwnProps } from './model'

export const InputRange = (props: OwnProps) => {
	const { min, max, value, view = 'old', onChange, onBlur } = props

	const [inputValue, setInputValue] = useState<string>(breaksHundreds(value))

	const handleChange = useCallback((value: number | string) => {
		if (isNotPositiveIntegerValue(value)) return

		if (Number.isFinite(min) && +value < min!) {
			onChange(min!)
			setInputValue(breaksHundreds(min!))

			return
		}

		if (Number.isFinite(max) && +value > max!) {
			onChange(max!)
			setInputValue(breaksHundreds(max!))

			return
		}

		onChange(+value)
		setInputValue(breaksHundreds(value))
	}, [])

	const handleBlur = useCallback(() => {
		if (onBlur) onBlur()
	}, [onBlur])

	useEffect(() => {
		handleChange(value)
	}, [value])

	const handleInputFocus = useCallback(() => {
		if (Number.isFinite(min) && +removeSpaces(inputValue) < min!)
			return setInputValue(breaksHundreds(min!))
	}, [inputValue])

	if (view === 'old') {
		return View({
			...props,
			inputValue,
			handleChange,
			handleInputFocus,
		})
	}

	return NewView({
		...props,
		inputValue,
		handleChange,
		handleBlur,
		handleInputFocus,
	})
}
