/**
 * Идентификаторы для аналтитики
 * НЕ УДАЛЯТЬ!
 */
export enum AnchorsOfForms {
	// @deprecated remove since 01.02.2021
	loan = 'loanform',

	// Форма займа с мультишагами
	application = 'applicationform',
	loanLayout = 'lf-layout',
	loanStep1 = 'lf-step-1',
	loanStep2 = 'lf-step-2',
	loanStep3 = 'lf-step-3',
	loanStepSuccess = 'lf-step-success',

	partner = 'partnerform',
	estateAgents = 'estate-agents-form',
	investor = 'investor',
	investorPopup = 'investorPopup',
	investConsult = 'investConsult',
	activate = 'activate',
}

export enum BlockAnchors {
	calculator = 'calculator',
	finConsult = 'finConsult',
	security = 'security',
}

/**
 * По этим идентификаторам скролим до блока с формами
 */
export enum AnchorsOfFormsWrappers {
	partner = 'w-partnerform',
	investorsFond = 'w-investors-fond-form',
}

export enum Masks {
	phone = '+7 (999) 999-99-99',
	phoneStartsWith8 = '8 (999) 999-99-99',
	passport = '9999 999999',
	stringDate = '99.99.9999',
	passportDivisionCode = '999-999',
	docKey = '********-****-****-****-************',
}

export const RegExps = {
	number: /^[0-9\s-]*$/,
	notNumber: /\D/g,
	onlyNumbers: /^(\s*[0-9]+\s*)+$/,
	phone: /\+?[7]\s\((\d{3})\)\s(\d{3})-(\d{2})-(\d{2})/,
	docKey: /[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}/,
}
