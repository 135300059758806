import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useWhatsUpActions } from '@providers/whats-app-actions'

import styles from './Layout.module.scss'

export const Layout = ({ children }) => {
	const { setWhatsAppShow } = useWhatsUpActions()
	const { ref, inView } = useInView({
		threshold: 0,
	})

	useEffect(() => {
		if (inView) {
			setWhatsAppShow(false)
		} else {
			setWhatsAppShow(true)
		}
	}, [inView])

	return (
		<div className={styles.root} ref={ref}>
			{children}
		</div>
	)
}
