import { apiUrls } from '@http/api-urls'
import { DadataAddress, DadataFio, DaDataSuggestion } from '@http/dadata/models'
import { transformAutocompleteAddressResponseToOptions } from '@http/dadata/tranformers/address'
import { transformAutocompleteFioResponseToOptions } from '@http/dadata/tranformers/fio'
import { httpService } from '@http/service'
import { HttpOptions } from '@http/service/options'
import { getConfigProperty } from '@shared/pipes/config'
import { isClient } from '@shared/utils/is-client'
import { CancelTokenSource } from 'axios'

interface AutocompleteRequestRunParams {
	query: string
	axiosCancelToken: CancelTokenSource
}

export interface AutocompleteRequestRunner {
	run(params: AutocompleteRequestRunParams): Promise<any>
}

export class ExternalDadataFioRequestRunner implements AutocompleteRequestRunner {
	run({
		axiosCancelToken,
		query,
	}: AutocompleteRequestRunParams): Promise<DaDataSuggestion<DadataFio>[]> {
		const httpOptions = new HttpOptions()
		httpOptions
			.withCredentials(false)
			.setCancelToken(axiosCancelToken)
			.setHeader('Authorization', `Token ${getConfigProperty('DADATA_TOKEN')}`)

		return httpService
			.post(apiUrls.externalDadataSuggestionsFio(), { query, count: 5 }, httpOptions)
			.then(transformAutocompleteFioResponseToOptions)
	}
}

export class DadataAddressRequestRunner implements AutocompleteRequestRunner {
	run({
		query,
		axiosCancelToken,
	}: AutocompleteRequestRunParams): Promise<DaDataSuggestion<DadataAddress>[]> {
		const httpOptions = new HttpOptions()
		httpOptions
			.withCredentials(false)
			.setCancelToken(axiosCancelToken)
			.setHeader('Authorization', `Token ${getConfigProperty('DADATA_TOKEN')}`)

		return httpService
			.get<any>(
				`${apiUrls.dadataSuggestionsAddress()}?query=${encodeURIComponent(query)}&count=5`,
				httpOptions
			)
			.then(transformAutocompleteAddressResponseToOptions)
	}
}
