import React, { PropsWithChildren, useCallback } from 'react'

import { TextFieldProps } from './model'
import { View } from './view'

export const TextField = ({ onPressEnter, ...props }: PropsWithChildren<TextFieldProps>) => {
	const handleKeyUp = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			if (onPressEnter && e.keyCode === 13) {
				onPressEnter(e)
			}

			props.onKeyUp && props.onKeyUp(e)
		},
		[props.onKeyPress, onPressEnter]
	)

	return View({ ...props, onKeyUp: handleKeyUp })
}
