export const isEmpty = (data: any): boolean => {
	if (data) {
		if (Array.isArray(data) && data.length > 0) {
			return false
		}
		if (data instanceof Object && Object.keys(data).length > 0) {
			return false
		}
	}

	return true
}

export const isNotEmpty = (data: any): boolean => !isEmpty(data)

export const removeSpaces = (value: string | number): string => value.toString().replace(/\s/g, '')

export const formatPhone = (phone?: string | null, withBrackets = false) => {
	if (!phone || !/^[0-9/+-\s]*$/.test(phone)) return ''

	let formattedPhone, phone1, phone2, phone3, phone4

	if (phone.length === 7) {
		phone1 = phone.slice(0, 3)
		phone2 = phone.slice(3, 5)
		phone3 = phone.slice(5, 7)
		formattedPhone = `+7 ${phone1}-${phone2}-${phone3}`
	} else if (phone.length === 10) {
		phone1 = phone.slice(0, 3)
		phone2 = phone.slice(3, 6)
		phone3 = phone.slice(6, 8)
		phone4 = phone.slice(8, 10)
		formattedPhone = withBrackets
			? `+7 (${phone1}) ${phone2}-${phone3}-${phone4}`
			: `+7 ${phone1} ${phone2}-${phone3}-${phone4}`
	} else {
		formattedPhone = `+7 ${phone}`
	}

	return formattedPhone
}

export const breaksHundreds = (value: string | number): string =>
	removeSpaces(value.toString()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')

export const formatCalcSum = (sum: number | string) => {
	const value = String(sum)

	const countZerosInThousand = 3
	const countZerosInBillion = 6

	if (value.length > countZerosInBillion) {
		return `${value.slice(0, -countZerosInBillion)} млн`
	}

	if (value.length > countZerosInThousand) {
		return `${value.slice(0, -countZerosInThousand)} тыс`
	}

	return value
}

export const emitGTMEvent = (eventName: string) => {
	;((window as any).dataLayer || []).push({
		event: eventName,
	})
}

export const formatLoanValue = (num: number) => {
	if (num > 999999) {
		return +(num / 1000000).toFixed(1) + ' млн'
	}

	if (num > 999) {
		return +(num / 1000).toFixed(1) + ' тыс'
	}

	return num
}

// возвращает true, если значение не равно положительному, целому числу или пустой строке
export const isNotPositiveIntegerValue = (value: string | number): boolean =>
	value !== '' && !/^[1-9]\d*$/.test(value.toString())
