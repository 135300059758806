import { RegExps } from '@constants/forms'
import { customizeYup } from '@shared/components/forms/shared/validation/customize-yup'
import { bool, mixed, object, string } from 'yup'

import { ApplicationFormDto } from '../../types'

customizeYup()

export const validationSchema = object().shape({
	participant: object().shape<Partial<ApplicationFormDto['participant']>>({
		fullname: string().nullable().required(),
		phone: string()
			.matches(RegExps.phone, {
				message: 'Указан неверный формат номера',
			})
			.required(),
		email: string().email().required(),
	}),
	purpose: mixed().required() as any,
})

export const validationSchemaWow = object().shape({
	participant: object().shape<Partial<ApplicationFormDto['participant']>>({
		fullname: string().nullable().required(),
		phone: string()
			.matches(RegExps.phone, {
				message: 'Указан неверный формат номера',
			})
			.required(),
		email: string().email().required(),
	}),
	agreementPersonalData: bool().required(),
	agreementAdNewsletter: bool().required(),
	purpose: mixed().required() as any,
})
