import React, { useCallback, useMemo, useState } from 'react'
import LazyLoad from 'react-lazyload'
import cx from 'classnames'
import imagesSize from 'data/images-size.json'

import { IOwnProps } from './model'

import styles from './style.module.scss'

const offExt = (name: string) => name.replace(/(\.\w+)$/g, '')

const sizeSplit = (size: string, name: string): number[] => {
	if (!size) {
		console.error('Не удалось найти изображение:', name)

		return [0, 0]
	}

	return size.split('/').map((i) => parseInt(i))
}

export const View = ({ name, alt, lazy, className }: IOwnProps) => {
	const [imageIsLoad, setImageLoad] = useState(false)

	const getpadding = useCallback((): string => {
		const [width, height] = sizeSplit(imagesSize[name], name)

		if (!width || !height || isNaN(width) || isNaN(height)) {
			console.error('Uncorrect size, use "x/y" format', name)

			return '100%'
		}

		return `${+(height / width).toFixed(2) * 100}%`
	}, [name])

	const getwidth = useCallback((): string => `${sizeSplit(imagesSize[name], name)[0]}px`, [name])

	const renderImage = useMemo(() => {
		const style = lazy ? { display: imageIsLoad ? 'block' : 'none' } : {}
		return (
			<picture>
				<source srcSet={`/images/webp/${offExt(name)}.webp`} type='image/webp' />
				<img
					alt={alt}
					style={style}
					className={className}
					src={`/images/${name}`}
					onLoad={() => setImageLoad(true)}
				/>
			</picture>
		)
	}, [name, alt, className, imageIsLoad, lazy])

	const renderImageFallback = useCallback(() => {
		if (!imageIsLoad)
			return (
				<div className={cx(styles.imageWrap, className)} style={{ maxWidth: getwidth() }}>
					<div className={styles.paddingContainer} style={{ paddingTop: getpadding() }}>
						<img
							src={`/images/webp/thumbnails/${offExt(name)}.webp`}
							alt={alt}
							className={styles.fallback}
						/>
					</div>
				</div>
			)

		return null
	}, [name, imageIsLoad])

	if (lazy) {
		return (
			<div className={styles.wrapper}>
				<div
					className={cx({
						[styles.imageNotLoad]: !imageIsLoad,
					})}
				>
					<LazyLoad offset={500}>{renderImage}</LazyLoad>
				</div>
				{renderImageFallback()}
			</div>
		)
	}

	return renderImage
}
