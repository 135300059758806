import React, { PropsWithChildren } from 'react'
import InputMask from 'react-input-mask'
import { Field } from 'formik'

import { Input } from './input'
import { TextFieldProps } from './model'

export const View = ({
	name,
	mask,
	maskChar = ' ',
	beforeMaskedValueChange,
	disabled,
	...rest
}: PropsWithChildren<TextFieldProps>) => {
	return (
		<Field name={name}>
			{({ field, meta }) => {
				const inputProps = {
					error: meta.touched ? meta.error : '',
					...field,
					...rest,
				}

				if (mask)
					return (
						<InputMask
							mask={mask}
							maskChar={maskChar}
							beforeMaskedValueChange={beforeMaskedValueChange}
							value={field.value}
							{...field}
							disabled={disabled}
						>
							{() => <Input {...inputProps} disabled={disabled} />}
						</InputMask>
					)

				return <Input {...inputProps} disabled={disabled} />
			}}
		</Field>
	)
}
