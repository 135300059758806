import { ProductByPage } from '@constants/product'
import { transformPhoneToDto } from '@http/service/shared-transformers'
import { getMarketingData } from '@shared/pipes/marketing-data'

import { ApplicationFormDto } from './types'

const defaultToApi = (value: any) => {
	if (typeof value === 'string') {
		const trimmedValue = value.trim()
		return trimmedValue || null
	}

	return value == null ? null : value
}

export const transformFormToDto = (
	form: ApplicationFormDto,
	shouldReceiveMarketingData = true,
	page?: string
) => {
	return {
		marketingData: Object.assign({}, shouldReceiveMarketingData ? getMarketingData() : null, {
			purpose: defaultToApi(form.purpose),
		}),
		calculation: {
			loan: defaultToApi(form.loan),
			period: defaultToApi(form.period),
			payment: defaultToApi(form.payment),
			percent: defaultToApi(form.percent),
			type: 'ANNUITY',
		},
		participants: [
			{
				name: defaultToApi(form.participant.name),
				surname: defaultToApi(form.participant.surname),
				patronymic: defaultToApi(form.participant.patronymic),
				phone: transformPhoneToDto(form.participant.phone),
				email: defaultToApi(form.participant.email),
				role: 'APPLICANT',
			},
		],
		product: page ? ProductByPage[page] : undefined,
	}
}
